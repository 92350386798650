.top5 {
    margin-top: 5px;
}

.top7 {
    margin-top: 7px;
}

.top10 {
    margin-top: 10px;
}

.top15 {
    margin-top: 15px;
}

.top17 {
    margin-top: 17px;
}

.top20 {
    margin-top: 20px;
}

.top30 {
    margin-top: 30px;
}

.top-40 {
    margin-top: -40px;
}

.m-t-1 {
    margin-top: 10px !important;
}

.m-t-2 {
    margin-top: 20px !important;
}

.m-t-3 {
    margin-top: 30px !important;
}

.m-t-4 {
    margin-top: 40px !important;
}

.mg-b15i {
    margin-bottom: 15px !important;
}

.mg-l10 {
    margin-left: 10px;
}

.mg-t10 {
    margin-top: 10px;
}

.align-left {
    float: left;
}

.align-right {
    float: right;
}