@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/select2/src/scss/core.scss";
@import "node_modules/select2-bootstrap-theme/src/select2-bootstrap.scss";
@import "node_modules/bootstrap-toggle/css/bootstrap2-toggle";
@import "node_modules/bootstrap-toggle/css/bootstrap-toggle";
@import "node_modules/toastr/toastr.scss";
@import "./components/bootstrap-datepicker.scss";

@import "./variables.scss";
@import "./select2.scss";
@import "./components/selectize.scss";
@import "./commons.scss";

body{
	background-color: $primary-color-grey;
}

.div__logo_bar--height{
	height: 83px;
}

.background-green{
	background-color: #00B39D !important;
}

.border-color-green{
	border-color: #00B39D !important;
}

.background-blue{
	background-color: #0081BB;
}

.div__logo_bar--size{
	vertical-align: middle;
	display: inline-block;
	width: 80%;
	height: 100%;
	margin-left: 9px;
}

.top-buffer { margin-top:20px; }

.gray-color{
	color:  #ECF3F7;
}

.h1_style{
	@extend .gray-color;
	font-family: PT Serif
}

.h5_style{
	@extend .gray-color;
	margin-top: -10px;
}

.inline {
    display:inline;
}

.footer{
	position: fixed;
	width: 100%;
	height: 60px;
	background-color: #cfd3d6;
	line-height: 60px;
	bottom: 0;
	p{
	    vertical-align: middle;
	    display: inline-block;
	}
}

.select2{
	width: 100%;
}

.row-centered {
    text-align:center;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.form-label__qt-ap{
	margin-top: 7px;
}

.select2-choices {
	min-height: 150px;
	max-height: 150px;
	overflow-y: auto;
}

.padding-0 {
	padding: 0;
}

hr.divider {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    min-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0;
}

hr.with_text.divider{
	margin-bottom: 0px;
}
hr.with_text.bottom.divider{
	margin-bottom: 15px;
}

#beneficiaries_message, #beneficiaries_simple_message {
 	overflow:auto;
}

.no-close-icon .close {
	display: none;
}

#panel3_finish{
	width: 65%;
}

#panel3_cancel{
	width: 30%;
}

.width75{
	width: 75%;
}

hr{
	margin-top: 17px;
}

hr.bottom{
	margin-top: 0;
}

h5.title_divider{
	text-align: center;
	font-weight: bold;
}

div.pager-actions .glyphicon, div.pager-actions .fa {
	font-size: 35px;
}

.glyphicon-wrapper{
    display: inline-block;
}

a.glyphicon-muted > span {
	opacity: 0.25;
}

a.glyphicon-muted:hover > span{
	opacity: 0.5;
}

.pager-actions p.text-description{
    margin-top: 17px;
}

#csv-upload {
	display: none;
}

input.dbl-height{
    height: 70px;
}

div.input-daterange .date:first-of-type{
	margin-bottom: 2px;
}

.price-width{
	width: 100px;
}

.small-disc-letter{
    font-size: 11px;
}

@import "./fixes.scss";

.middle-vertical-align{
    padding-right: 10px;
    vertical-align: middle;
}

.group-details{
    border-bottom:  1px;
    border-top:     0px;
    border-left:    0px;
    border-right:   0px;
    border-style:   dashed;
    border-color:   black;
    min-width:      185px;
    padding-bottom: 5px;
}

.label_required:after{
	color: red;
    content: " *";
}

.label_optional:after{
	color: grey;
    content: " (opcional)";
}